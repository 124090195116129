import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241')
];

export const server_loads = [0,25,28,27,29,30,31,32,34,37,2,4,6,7,8,9,10,11,12,13,15,16,17,18,24,19,21];

export const dictionary = {
		"/": [~41],
		"/admin": [~126,[25],[26]],
		"/admin/companies": [~127,[25],[26]],
		"/admin/companies/[companySlug]": [~128,[25],[26]],
		"/admin/companies/[companySlug]/edit": [~129,[25],[26]],
		"/admin/companies/[companySlug]/edit/accesses": [130,[25],[26]],
		"/admin/companies/[companySlug]/edit/licenses": [131,[25],[26]],
		"/admin/companies/[companySlug]/projects": [~132,[25],[26]],
		"/admin/companies/[companySlug]/requests": [~133,[25],[26]],
		"/admin/companies/[companySlug]/users": [~134,[25],[26]],
		"/admin/country-risk": [~135,[25],[26]],
		"/admin/devtools": [136,[25],[26]],
		"/admin/devtools/details": [~137,[25],[26]],
		"/admin/emails": [~138,[25],[26]],
		"/admin/emails/email-dashboard": [~140,[25],[26]],
		"/admin/emails/templates": [~141,[25],[26]],
		"/admin/emails/templates/[templateId]": [~142,[25],[26]],
		"/admin/emails/[emailId]": [~139,[25],[26]],
		"/admin/event-log": [~143,[25],[26]],
		"/admin/licenses": [144,[25],[26]],
		"/admin/licenses/susan": [~145,[25],[26]],
		"/admin/merge": [~146,[25],[26]],
		"/admin/merge/[fromId]/[toId]": [~147,[25],[26]],
		"/admin/merge/[fromId]/[toId]/dry-run-successful": [~148,[25],[26]],
		"/admin/merge/[fromId]/[toId]/merge-successful": [~149,[25],[26]],
		"/admin/new-company": [150,[25],[26]],
		"/admin/questions": [~151,[25],[26]],
		"/admin/questions/add": [~163,[25],[26]],
		"/admin/questions/options": [164,[25],[26]],
		"/admin/questions/options/add": [~168,[25],[26]],
		"/admin/questions/options/[optionId]": [~165,[25],[26]],
		"/admin/questions/options/[optionId]/edit": [~166,[25],[26]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~167,[25],[26]],
		"/admin/questions/restructure": [~169,[25],[26]],
		"/admin/questions/sets": [~170,[25],[26]],
		"/admin/questions/sets/[questionSetId]": [~171,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/access": [~172,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/danger-zone": [~173,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~174,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/print": [~175],
		"/admin/questions/[questionId]": [152,[25,27],[26]],
		"/admin/questions/[questionId]/danger-zone": [~153,[25,27],[26]],
		"/admin/questions/[questionId]/details": [~154,[25,27],[26]],
		"/admin/questions/[questionId]/edit": [155,[25,27],[26]],
		"/admin/questions/[questionId]/follow-ups": [~156,[25,27],[26]],
		"/admin/questions/[questionId]/localizations": [157,[25,27],[26]],
		"/admin/questions/[questionId]/localizations/[locale]": [~158,[25,27],[26]],
		"/admin/questions/[questionId]/options": [~159,[25,27],[26]],
		"/admin/questions/[questionId]/scores": [~160,[25,27],[26]],
		"/admin/questions/[questionId]/ungc": [~161,[25,27],[26]],
		"/admin/questions/[questionId]/update-ungc": [~162,[25,27],[26]],
		"/admin/reports": [176,[25],[26]],
		"/admin/reports/companies": [~177,[25],[26]],
		"/admin/reports/suppliers": [~178,[25],[26]],
		"/admin/reports/surveys": [~179,[25],[26]],
		"/admin/sent-emails/[email]/[emailType]": [~180],
		"/admin/suggested-follow-ups": [~181,[25],[26]],
		"/admin/suggested-follow-ups/[questionId]": [~182,[25,29],[26]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~183,[25,29],[26]],
		"/admin/taxonomy": [184,[25,30],[26]],
		"/admin/taxonomy/version": [205,[25,30],[26]],
		"/admin/taxonomy/version/[versionId]": [~206,[25,30],[26]],
		"/admin/taxonomy/year": [~207,[25,30],[26]],
		"/admin/taxonomy/[versionId]": [185,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities": [186,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~187,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activity-based": [~188,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories": [~189,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories/edit": [~190,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/company-wide": [~191,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/company-wide/text": [~192,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/expiration": [~193,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts": [194,[25,30,31,33],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~195,[25,30,31,33],[26]],
		"/admin/taxonomy/[versionId]/question-sets": [~199,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~200,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~196,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~197,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~198,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group": [~201,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~202,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors": [203,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~204,[25,30,31],[26]],
		"/admin/transparency-act-steps": [~208,[25],[26]],
		"/admin/transparency-act-steps/add": [212,[25],[26]],
		"/admin/transparency-act-steps/checklist-item": [~213,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~214,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~215,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]": [~209,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [210,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~211,[25],[26]],
		"/admin/users": [~216,[25],[26]],
		"/admin/users/[userId]": [~217,[25],[26]],
		"/admin/users/[userId]/edit": [~218,[25],[26]],
		"/admin/workers": [~219,[25,34],[26]],
		"/data-processing-agreement": [220,[35]],
		"/login": [221],
		"/logout": [222],
		"/maintenance": [223],
		"/notifications": [~224,[36]],
		"/survey-invitation/[invitationId]": [~225,[37]],
		"/survey-invitation/[invitationId]/access-requested": [~226,[37]],
		"/survey-invitation/[invitationId]/company-created": [~227,[37]],
		"/survey-invitation/[invitationId]/declined": [229,[37]],
		"/survey-invitation/[invitationId]/decline": [228,[37]],
		"/survey-invitation/[invitationId]/forwarded": [231,[37]],
		"/survey-invitation/[invitationId]/forward": [230,[37]],
		"/survey-invitation/[invitationId]/new-company": [~232,[37]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [233,[37]],
		"/survey-invitation/[invitationId]/no-access": [~234,[37]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~235,[37]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~236,[37]],
		"/survey-invitation/[invitationId]/select-company": [~237,[37]],
		"/terms-of-use": [238,[38]],
		"/user": [239,[39],[40]],
		"/user/access-tokens": [~240,[39],[40]],
		"/user/access-tokens/create": [~241,[39],[40]],
		"/[companySlug]": [~42,[2],[3]],
		"/[companySlug]/admin/add-company": [~43,[2],[3]],
		"/[companySlug]/company": [~44,[2,4],[3]],
		"/[companySlug]/company/accesses": [~45,[2,4],[3]],
		"/[companySlug]/company/assessments": [~46,[2,4],[3]],
		"/[companySlug]/company/documents": [~47,[2,4],[3]],
		"/[companySlug]/company/edit": [48,[2,4],[3]],
		"/[companySlug]/company/licenses": [~49,[2,4],[3]],
		"/[companySlug]/company/overview": [~50,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~51,[2,4],[3]],
		"/[companySlug]/company/settings": [~52,[2,4,5],[3]],
		"/[companySlug]/company/settings/login": [53,[2,4,5],[3]],
		"/[companySlug]/company/settings/spend": [~54,[2,4,5],[3]],
		"/[companySlug]/company/settings/tags": [~55,[2,4,5],[3]],
		"/[companySlug]/company/users": [~56,[2,4],[3]],
		"/[companySlug]/customers": [~57,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~58,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [59,[2,6],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~60,[2,6],[3]],
		"/[companySlug]/follow-ups": [61,[2,7],[3]],
		"/[companySlug]/follow-ups/received": [~62,[2,7],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~63,[2],[3]],
		"/[companySlug]/follow-ups/sent": [~64,[2,7],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~65,[2],[3]],
		"/[companySlug]/projects": [~66,[2,8],[3]],
		"/[companySlug]/projects/add": [~74,[2,8],[3]],
		"/[companySlug]/projects/add/preview": [~75,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(main)": [67,[2,8,9],[3]],
		"/[companySlug]/projects/[projectId]/(main)/chain/[chainId]": [~68,[2,8,9],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/edit-suppliers-list": [~69,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview": [~70,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview/print": [~71],
		"/[companySlug]/projects/[projectId]/(sub-pages)/send-survey": [~72,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/settings": [~73,[2,8],[3]],
		"/[companySlug]/relogin": [~76],
		"/[companySlug]/saq/[questionSetId]": [~77,[10]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~78,[10]],
		"/[companySlug]/saq/[questionSetId]/finish": [~79,[11]],
		"/[companySlug]/suppliers": [~80,[2,12],[3]],
		"/[companySlug]/suppliers/add": [~93,[2,12],[3]],
		"/[companySlug]/suppliers/upload": [~94,[2,12],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~95,[2,12],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~96,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~81,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~82,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [83,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~84,[2,12,13,14],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/internal": [~86,[2,12,13,14],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~85,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~87,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [~88,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~89,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~90,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~91,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~92],
		"/[companySlug]/surveys": [~97,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~98,[15]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~99],
		"/[companySlug]/surveys/[surveyId]/answer": [~100,[15,16]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~101,[15,16]],
		"/[companySlug]/surveys/[surveyId]/finish": [~102,[17]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~103,[17]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~104],
		"/[companySlug]/taxonomy": [~105,[18]],
		"/[companySlug]/taxonomy/overview": [~124,[18,24]],
		"/[companySlug]/taxonomy/overview/import-report": [~125,[18,24]],
		"/[companySlug]/taxonomy/[reportId]": [106,[19]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~107,[19,20]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~108,[19,20]],
		"/[companySlug]/taxonomy/[reportId]/documents": [~109,[19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~110,[19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~111,[19,21]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~112,[19,21]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~113,[19,22]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~115,[19,22]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [116,[19,22]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~114,[19,22]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~117,[19]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~118,[19]],
		"/[companySlug]/taxonomy/[reportId]/report": [119,[19]],
		"/[companySlug]/taxonomy/[reportId]/start": [~120,[19]],
		"/[companySlug]/taxonomy/[reportId]/summary": [121,[19,23]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~122,[19,23]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~123,[19,23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';